import React from 'react';
import {
  List as ComponentList,
  Edit as ComponentEdit,
  EditButton,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  DateField,
  SelectInput,
  SearchInput,
} from 'react-admin';

const PostFilter = [
  <TextInput label={'id'} source="id" alwaysOn />,
  <TextInput label={'Номер телефона'} source="phone" alwaysOn />,
];

export const List = (props) => {
  return (
    <ComponentList filters={PostFilter}>
      <Datagrid>
        <TextField label={'ID'} source='id' sortable={false} />
        <TextField label={'ID профиля'}source='ProfileId' sortable={false} />
        <TextField label={'Номер телефона'} source='phone' sortable={false} />
        <TextField label={'Статус'} source='status' sortable={false} />
        <TextField label={'Роль'} source='Role.name' sortable={false} />
        <DateField label={'Дата регистрации'} showTime={true} source='createdAt' sortable={false} />
        <EditButton />
      </Datagrid>
    </ComponentList>
  );
};

export const Edit = (props) => {
  return (
    <ComponentEdit title={'Edit user'} {...props}>
      <SimpleForm>
        <TextInput disabled source='id' />
        <TextInput disabled source='phone' />

        {/* <ReferenceInput label="Author" source="RoleId" reference="Role">
          <SelectInput />
        </ReferenceInput> */}

        <SelectInput source="RoleId" choices={[
            { id: '3', name: 'Пользователь' },
            { id: '2', name: 'Модератор' },
            { id: '1', name: 'Админ' },
        ]}/>
        
        <SelectInput source="status" choices={[
            { id: 'active', name: 'Активный' },
            { id: 'blocked', name: 'Заблокирован' },
        ]}/>

        <TextInput multiline fullWidth source='settings' />
      </SimpleForm>
    </ComponentEdit>
  );
};

