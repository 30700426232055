import React, { useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import { authProvider, dataProviderCustom } from './libs/providers';
import { Auth } from './libs/auth';
import { ReCaptcha } from './libs/reCaptcha';
import * as Users from './components/Users';
import * as Profiles from './components/Profiles';
import * as Publications from './components/Publications';
import * as Reactions from './components/Reactions';
import * as Ratings from './components/Ratings';
import * as Notifications from './components/Notifications';
import * as NotificationTypes from './components/NotificationTypes';
import * as Regions from './components/Regions';
import * as Cities from './components/Cities';
import * as Districts from './components/Districts';


function App() {
  useEffect(() => {
    const fingerprint = Auth.getFingerPrint();

    if (!fingerprint) {
      Auth.setFingerPrint();
    }

    ReCaptcha.load();
  }, []);

  return (
    <Admin authProvider={authProvider} dataProvider={dataProviderCustom()}>
      <Resource name='users' options={{ label: 'Пользователи' }} list={Users.List} edit={Users.Edit} />
      <Resource name='profiles' options={{ label: 'Профили' }} list={Profiles.List} edit={Profiles.Edit} />
      <Resource name='publications' options={{ label: 'Объявления' }} list={Publications.List} />
      <Resource name='reactions' options={{ label: 'Отклики' }} list={Reactions.List} />
      <Resource name='ratings' options={{ label: 'Отзывы' }} list={Ratings.List} />
      <Resource name='notifications' options={{ label: 'Уведомления' }} list={Notifications.List} />
      <Resource name='notification-types' options={{ label: 'Типы уведомлений' }} list={NotificationTypes.List} edit={NotificationTypes.Edit} create={NotificationTypes.Create} />
      <Resource name='Regions' options={{ label: 'Регионы' }} list={Regions.List} edit={Regions.Edit} create={Regions.Create} />
      <Resource name='Cities' options={{ label: 'Города' }} list={Cities.List} edit={Cities.Edit} create={Cities.Create} />
      <Resource name='Districts' options={{ label: 'Районы' }} list={Districts.List} edit={Districts.Edit} create={Districts.Create} />
    </Admin>
  );
}

export default App;
